<script lang="ts">
  import { readable, type Readable } from 'svelte/store';
  import { onMount } from 'svelte';

  import DiscoverTeaser from './discover-teaser.svelte';

  import { fetchFrontFeedList } from '$stores/teaser-store';
  import { type UserStore } from '$stores/user-store';
  import type { DomainKeys } from '$stores/sites-store';
  import type { NormalizedTeaserResponse } from '$types/teaser-types';

  export let parameters: {
    userData: Exclude<UserStore['data'], null>;
    domainKeys: DomainKeys;
    primarySite: string;
  };

  const { uuid, trackingKey } = parameters.userData;
  const { domainKeys, primarySite } = parameters;

  let teaserListPromise = fetchFrontFeedList({
    trackingKey,
    uuid,
    location: 'explore',
  });

  let teaserList: Readable<NormalizedTeaserResponse[]> = readable([]);

  onMount(async () => {
    const list = await teaserListPromise;
    teaserList = readable(list.teasers);
  });
</script>

{#key teaserList}
  <DiscoverTeaser
    {teaserList}
    {domainKeys}
    {primarySite}
    options={{ debug: false, resumeId: null }}
  />
{/key}

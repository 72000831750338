<script lang="ts">
  import { readable } from 'svelte/store';

  import { css } from '$utils/utils';
  import SiteProfile from '$components/common/site-profile/site-profile.svelte';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import Button from '$components/style/button/button.svelte';
  import { type ClientConfigSite } from '$stores/sites-store';
  import { fetchFrontFeedList } from '$stores/teaser-store';
  import { user, UserState } from '$stores/user-store';
  import type { AdType } from '$types/ad-type';

  export let publicationsList: ClientConfigSite[] = [];

  const queryParams = new URLSearchParams(window.location.search);
  const siteKey = queryParams.get('sitekey') || '';
  const { missionStatement, domain, frontId, editor, location } =
    publicationsList.find((site) => site.siteKey === siteKey) || {};

  const { name, phone } = editor || {};

  const { visiting, postal } = location?.address || {};

  let verticalWrapperWidth = 0;
  let toggleFeed = true;

  let contact = [
    {
      title: 'Ansvarlig redaktør/daglig leder',
      value: name,
    },
    { title: 'Telefon', value: phone },
    { title: 'Besøksadresse', value: visiting },
    { title: 'Postadresse', value: postal },
  ];

  const buttonsStyle = css({
    marginTop: '$x4',
  });

  const bottomContentStyle = css({
    paddingInline: '$x4',
    display: 'flex',
    flexDirection: 'column',
  });

  const textBodyStyle = css({
    listStyleType: 'none',
    paddingLeft: '0px',
    fontstyle: 'baseBodyS',
    margin: '0px',
  });

  const contactObjectStyle = css({
    display: 'flex',
    flexDirection: 'column',
  });

  const textTitleStyle = css({
    fontstyle: 'baseHeadlineS',
    marginTop: '$x4',
  });

  let teaserListPromise = fetchFrontFeedList({
    trackingKey: null,
    uuid: null,
    location: 'frontpage',
    url: `/api/ordino/frontpage/${siteKey}/${frontId}/wide`,
  });

  let adType =
    verticalWrapperWidth > 580 ? 'netboard' : ('midtbanner' as AdType);
  $: showFeed = $user.state >= UserState.HASACCESS && toggleFeed;
</script>

<SiteProfile {siteKey} sites={publicationsList} />

<div class={bottomContentStyle()}>
  {#if missionStatement}
    <span class={textTitleStyle()}>Formål</span>
    <span class={textBodyStyle()}>{missionStatement}</span>
  {/if}

  {#if $user.state >= UserState.HASACCESS}
    <div class={buttonsStyle()}>
      <Button
        clickLabel="profile"
        clickValue="newsfeed"
        text="Nyheter"
        on:click={() => (toggleFeed = true)}
        selected={toggleFeed}
      />
      <Button
        clickLabel="profile"
        clickValue="contact"
        text="Kontakt"
        on:click={() => (toggleFeed = false)}
        selected={!toggleFeed}
      />
    </div>
  {/if}

  {#if !showFeed}
    <ul class={textBodyStyle()}>
      {#each contact as { title, value }, index (index)}
        {#if value}
          <li class={contactObjectStyle()}>
            <span class={textTitleStyle()}>{title}</span>
            <span>{value}</span>
          </li>
        {/if}
      {/each}
    </ul>
  {/if}
</div>

{#if showFeed}
  {#await teaserListPromise then list}
    {@const teaserList = readable(list.teasers)}
    <Teaserlist
      primarySite={siteKey}
      {adType}
      {teaserList}
      options={{}}
      domainKeys={{ [domain ?? '']: siteKey }}
      filter={siteKey}
    />
  {/await}
{/if}

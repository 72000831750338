<script lang="ts">
  import { onMount } from 'svelte';
  import { readable } from 'svelte/store';

  import { css } from '$utils/utils';
  import AltPollTeaser from '$components/amedia/alt-poll-teaser/component/alt-poll-teaser.component.svelte';
  import Filter from '$components/common/filter/filter.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import ModalDialog from '$components/style/modal/modal-dialog.svelte';
  import PublicationRecommenderWrapper from '$components/common/site-recommender/site-recommender.wrapper.svelte';
  import SitesRecommenderTeaserWrapper from '$components/common/site-recommender-teaser/site-recommender-teaser.wrapper.svelte';
  import SitesSelector from '$components/common/sites-selector/sites-selector.svelte';
  import SubscriptionsWidget from '$components/common/widgets/subscriptions-widget.svelte';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import i18n from '$i18n/i18n.js';
  import { customClickEvent } from '$utils/adplogger.js';
  import { savePublicationPreferences } from '$utils/queries.js';
  import { fetchFrontFeedList } from '$stores/teaser-store';
  import type { DomainKeys, SitesDomains } from '$stores/sites-store';
  import { dirtySelectedSites, user } from '$stores/user-store';
  import DiscoverTeaserWrapper from '$components/common/teaserlist/discover-teaser-wrapper.svelte';
  import Button from '$components/style/button/button.svelte';
  import type { AdType } from '$types/ad-type';
  import { ALL } from '$utils/consts';

  type HomeProps = {
    uuid: string;
    trackingKey: string | null;
    primarySite: string;
    sitesDomains: SitesDomains;
    domainKeys: DomainKeys;
    innerWidth: number;
    dialogComponent: HTMLDialogElement;
    sortedOptions: [string, string][];
    filter: string;
    setFilter: (newFilter: string) => void;
    adType?: AdType;
  };

  let {
    uuid,
    trackingKey,
    primarySite,
    sitesDomains,
    domainKeys,
    innerWidth,
    dialogComponent = $bindable(),
    sortedOptions,
    filter,
    setFilter,
    adType = 'unknown',
  }: HomeProps = $props();

  const debug =
    new URL(window.location.href).searchParams.get('debug') === 'true';

  const resumeId = window.sessionStorage.getItem('altResumeId');

  const options = {
    debug,
    resumeId,
  };

  let filterScrollList: HTMLElement | undefined = $state(undefined);

  const errorStyle = css({
    margin: '$x6 $x4 $x20',
  });

  const displayContentStyle = css({
    display: 'contents',
  });

  const changeSitesStyle = css({
    margin: 'calc(-1 * $x3) auto $x8',
    paddingInline: '1rem',
    '@media screen and (min-width: 768px)': {
      paddingInline: '2rem',
    },
  });

  let teaserListPromise = $state(
    fetchFrontFeedList({
      trackingKey,
      uuid,
      location: 'frontpage',
    })
  );

  let showFilter = $derived(sortedOptions.length > 1);

  $effect(() => {
    if ($dirtySelectedSites)
      teaserListPromise = fetchFrontFeedList({
        trackingKey,
        uuid,
        location: 'frontpage',
        forceRefresh: true,
      });
  });

  let selectedSites = $derived(
    $user.data?.preferredSites.map(({ siteKey }) => siteKey) || []
  );

  const ratingIndex = 9;
  const pollIndex = 12;

  const getComponentByIndex = (index: number, innerWidth: number) => {
    switch (index) {
      case 3: {
        return DiscoverTeaserWrapper;
      }
      case 7: {
        return SitesRecommenderTeaserWrapper;
      }
      case ratingIndex: {
        return AltPollTeaser;
      }
      case pollIndex: {
        return AltPollTeaser;
      }
      case 12: {
        if (innerWidth < 768) return SubscriptionsWidget;
        break;
      }
    }
    // We need to type this as any else Typescript will complain about the missing $$props type in the markup below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  };

  const onUpdate = (ev: CustomEvent) => {
    handleSelectedSites(ev);
  };

  const handleSelectedSitesClose = () => {
    dialogComponent.close();
  };

  // This is called when on:update is triggered in the app (such as in the sites-selector)
  // Needs to be replaced as events dispatching is outdated
  const handleSelectedSites = async (e: CustomEvent) => {
    const { siteKey, checked } = e.detail;

    const event = customClickEvent(
      'mypublications-update',
      JSON.stringify({
        siteKey,
        preferred: checked,
      })
    );
    e.target?.dispatchEvent(event);

    setFilter(ALL);
    await savePublicationPreferences({
      siteKey,
      preferred: checked,
    });

    if (filterScrollList) {
      filterScrollList.scrollLeft = 0;
    }
  };

  onMount(async () => {
    await teaserListPromise;
  });

  let siteSelectorOpen = $state(false);
</script>

<ModalDialog
  id="sites-selector"
  bind:dialogComponent
  close={() => {
    siteSelectorOpen = false;
  }}
  open={() => {
    siteSelectorOpen = true;
  }}
>
  <SitesSelector
    visible={siteSelectorOpen}
    container={dialogComponent}
    on:close={handleSelectedSitesClose}
    on:update={handleSelectedSites}
  />
</ModalDialog>

<PublicationRecommenderWrapper
  {uuid}
  {primarySite}
  {selectedSites}
  on:update={(ev) => {
    handleSelectedSites(ev);
  }}
/>

{#if showFilter}
  <Filter
    {sortedOptions}
    {sitesDomains}
    {setFilter}
    {filter}
    bind:list={filterScrollList}
  >
    <Button
      text={$i18n.t('frontpage.buttons.changeSites')}
      size="none"
      type="signal"
      clickLabel="filter-feed"
      clickValue="add-more"
      inlinePadding="$x4"
      blockPadding="$x2"
      id="add-more"
      on:click={() => {
        dialogComponent.showModal();
      }}
    />
  </Filter>
{:else}
  <div class={changeSitesStyle()}>
    <Button
      text="Legg til aviser"
      size="none"
      type="signal"
      clickLabel="filter-feed"
      clickValue="add-more"
      inlinePadding="$x4"
      blockPadding="$x2"
      id="add-more"
      fullWidth
      on:click={() => {
        dialogComponent.showModal();
      }}
    />
  </div>
{/if}

{#await teaserListPromise then list}
  {@const teaserList = readable(list.teasers)}
  <div
    itemscope
    itemtype="https://www.adplogger.no/json-schema/CustomElement"
    class={displayContentStyle()}
  >
    <meta
      itemprop="custom-element#data"
      content={JSON.stringify({ tracer_request_id: list.id })}
    />
    <meta itemprop="custom-element#name" content="ordino_front_feed" />
    <Teaserlist
      {teaserList}
      {options}
      {domainKeys}
      {adType}
      {primarySite}
      {filter}
      showNewSiteTeaser={true}
    >
      <svelte:fragment slot="after" let:index>
        {@const Component = getComponentByIndex(index, innerWidth)}
        <Component
          {selectedSites}
          {primarySite}
          {uuid}
          on:update={onUpdate}
          parameters={{
            pollType: index === pollIndex ? 'poll' : 'rating',
            userData: {
              uuid,
              trackingKey,
            },
            options: options,
            domainKeys: domainKeys,
            primarySite: primarySite,
            handleSelectedSites: handleSelectedSites,
          }}
        />
      </svelte:fragment>
    </Teaserlist>
  </div>
{:catch error}
  <div class={errorStyle()}>
    <InfoBox title={$i18n.t('log.error.title')} hasLogo>
      <p slot="text">{error.message}</p>
    </InfoBox>
  </div>
{/await}

<script lang="ts">
  import { derived, type Readable } from 'svelte/store';

  import { css } from '$utils/utils';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import Icon from '$components/style/icon/icon.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import i18n from '$i18n/i18n.js';
  import type { DomainKeys } from '$stores/sites-store';
  import { customClickEvent } from '$utils/adplogger';
  import { savePublicationPreferences } from '$utils/queries';
  import { user } from '$stores/user-store';
  import type { AdType } from '$types/ad-type';
  import type { NormalizedTeaserResponse } from '$types/teaser-types';

  export let primarySite: string;
  export let domainKeys: DomainKeys;

  const feedStyle = css({
    backgroundColor: '$baseBg',
    display: 'block',
    margin: '0 auto',
    paddingBottom: '$x10',
  });

  const titleStyle = css({
    marginBlock: '$x6',
    paddingInline: '$x4',
    fontstyle: 'baseHeadlineL',
    color: '$buttonPrimaryFg',
  });

  const infoBoxWrapperStyle = css({
    maxWidth: '480px',
    margin: '$x6 auto $x4',
    paddingInline: '$x4',
  });

  const infoBoxStyle = css({
    display: 'flex',
    alignContent: 'center',
    gap: '$x3',
    fontstyle: 'baseBodyS',

    '&>*': {
      flex: 'none',
    },
  });

  const debug =
    new URL(window.location.href).searchParams.get('debug') === 'true';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO FIXME!
  const teaserList: Readable<NormalizedTeaserResponse[]> = derived(
    user,
    ($user) => $user?.data?.bookmarks ?? [],
    []
  );

  export const handleSelectedSites = async (e: CustomEvent) => {
    const { siteKey, checked } = e.detail;

    const event = customClickEvent(
      'mypublications-update',
      JSON.stringify({
        siteKey,
        preferred: checked,
      })
    );
    e.target?.dispatchEvent(event);

    await savePublicationPreferences({
      siteKey,
      preferred: checked,
    });
  };

  let options = { debug, page: 0 };

  let verticalWrapperWidth = 0;

  $: adType =
    verticalWrapperWidth > 580 ? 'netboard' : ('midtbanner' as AdType);
</script>

<main
  class={feedStyle()}
  id="main-content-begin"
  bind:clientWidth={verticalWrapperWidth}
>
  <h1 class={titleStyle()}>{$i18n.t('readinglist.heading')}</h1>
  {#if $teaserList.length}
    <Teaserlist
      {primarySite}
      {adType}
      {teaserList}
      {options}
      {domainKeys}
      on:updateSites={handleSelectedSites}
    />
  {:else}
    <div class={infoBoxWrapperStyle()}>
      <InfoBox>
        <p slot="text" class={infoBoxStyle()}>
          <Icon icon="bookmark-outline" size={22} />
          Du kan lagre saker til senere ved å trykke på bokmerke-ikonet.
        </p>
      </InfoBox>
    </div>
  {/if}
</main>
